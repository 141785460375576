import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ArcGISViewContext from "../../../../../context/ArcGISViewContext";
import { AttachmentTemplate } from "../../../../../map/PopupTemplate";

const LeafItem = (props: { layer: __esri.FeatureLayer }) => {
  const { layer } = props;

  const context = useContext(ArcGISViewContext);
  const [checked, setChecked] = useState<boolean>(layer?.visible ?? false);

  const LeafItemDiv = styled("div")(({ theme }) => ({
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));

  useEffect(() => {
    layer.popupTemplate = AttachmentTemplate(context.activeView);
    layer.popupEnabled = true;
  }, []);

  reactiveUtils.when(
    () => layer?.visible,
    () => {
      if (layer.geometryType === "polygon") {
        (layer.parent as __esri.GroupLayer).reorder(layer, 0);
        context.activeView?.map.reorder(layer.parent as __esri.Layer, 0);
      }
    }
  );

  const onChangeValue = () => {
    const newValue = !layer.visible;
    layer.visible = newValue;
    (layer.parent as __esri.GroupLayer).visible = true;
    setChecked(newValue);
  };

  return (
    <LeafItemDiv>
      <FormGroup>
        <FormControlLabel
          label={
            <Typography sx={{ fontStyle: layer?.title ? null : "italic" }}>
              {layer?.title ?? "Laster..."}
            </Typography>
          }
          control={<Checkbox checked={checked} onChange={onChangeValue} />}
        ></FormControlLabel>
      </FormGroup>
    </LeafItemDiv>
  );
};

export default LeafItem;
