import CustomContent from "@arcgis/core/popup/content/CustomContent.js";
import { FieldsInfo2DLayer } from "./OutputFieldsScenes";

const contentFieldsWithValue = new CustomContent({
  outFields: FieldsInfo2DLayer.map((field) => field.fieldName),
  creator: async (event: any) => {
    const alias = event.graphic.sourceLayer.fields.map((f: any) => {
      return { name: f.name, alias: f.alias };
    });
    const attributes = event.graphic.attributes;
    return render(attributes, alias);
  },
});

const render = (attributes: any, alias: any) => {
  let rowContent = [];
  const excludeFields = ["objectid_1", "globalid", "rgb_color"];

  for (const [key, value] of Object.entries(attributes)) {
    // Is the value defined at all?
    const conditionValueDefined = value !== null && value !== undefined;

    // Does the field have a value?
    const conditionFieldHasValue =
      (typeof value === "string" &&
        value.trim().length !== 0 &&
        value.trim() !== "-") ||
      typeof value === "number";

    // Exclude fields from being displayed altogether
    const conditionFieldValid = !excludeFields.includes(key.toLowerCase());

    // Gather all these conditions into one variable
    const renderCondition =
      conditionValueDefined && conditionFieldHasValue && conditionFieldValid;

    if (renderCondition) {
      let title =
        (Object.values(alias).filter((v: any) => v.name == key)[0] as any)
          ?.alias || key;

      const isUrl =
        typeof value === "string" &&
        (value.startsWith("https://") || value.startsWith("http://"));

      const valueToDisplay = isUrl
        ? `<a href="${value}" target="_blank">${value}</a>`
        : value;

      // Add row content here
      rowContent.push(`<tr>
                    <th class="esri-feature-fields__field-header">${title}</th>
                    <td class="esri-feature-fields__field-data">${valueToDisplay}</td>
                    </tr>`);
    }
  }

  let rowData =
    rowContent.length > 0
      ? rowContent.join("")
      : "<tr><td class='esri-feature-fields__field-data'>Ingen data</td></tr>";

  return `<div class="esri-feature-fields">
            <table class="esri-widget__table" summary="Liste med attributter og verdier">
            <tbody>
            ${rowData}
            </tbody>
            </table>
            </div>`;
};

export default contentFieldsWithValue;
